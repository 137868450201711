/* eslint-disable @typescript-eslint/no-loop-func */
import React, { ReactNode, useContext, useEffect, useState } from "react"
import * as Sentry from "@sentry/react"
import { Content } from "@prismicio/client"
import * as prismicH from "@prismicio/helpers"
import { useRouter } from "next/router"
import Script from "next/script"
import { ProgressBar, Prompt, Notification } from "components"
import SignupStepHandler from "components/pages/signup/SignupStepHandler"
import { useSignupData } from "hooks"
import { PageContainer } from "styles"
import {
  PrismicSignStepType,
  goToPage,
  // postHogStepInit,
  getPreviousStepsAndSections,
  createTN2Client,
  getTranslations,
  TranslationType,
  getValidationRulesAndMessages,
  getRiskWarnings
} from "utils"
import { createClient } from "../../prismicio"
import useHelpscout from "hooks/useHelpscout"
import { Context } from "pages/_app"

type Props = {
  step: PrismicSignStepType
  steps: PrismicSignStepType[]
  translation: TranslationType
  validation: {
    messages: Content.ValidationmessagesDocument
  }
  sectionTranslation: Content.TranslationDocument
}

const PageStep = ({ step, steps, translation, validation }: Props) => {
  return <SignupStepHandler step={step} steps={steps} translation={translation} validation={validation} />
}

declare global {
  interface Window {
    Beacon?: (arg0: string, arg1?: string) => void
  }
}

type PageStepLayoutProps = {
  children: ReactNode
} & Props

PageStep.Layout = ({ children, step, steps, translation }: PageStepLayoutProps) => {
  const { validationMessages } = useContext(Context)
  const [isTokenValid, setIsTokenValid] = useState(true)
  const { asPath } = useRouter()
  const { stepUid } = useSignupData()

  useHelpscout()

  useEffect(() => {
    /** 
      @description Redirect to welcome page if no access token is found or if the token is expired
    */

    const authToken = localStorage.getItem("access_token") || window.location.href.includes("access_token")

    if (step && step.uid && step.uid !== "welcome") {
      if (!window.location.search.includes("callback")) {
        if (!authToken) {
          goToPage("/signup/welcome")
        }
      }
    }

    const checkTokenValidity = () => {
      const expirationTime = localStorage.getItem("token_expiration_time")
      if (expirationTime) {
        const currentTime = new Date().getTime()

        if (currentTime < Number(expirationTime)) {
          setIsTokenValid(true)
        } else {
          setIsTokenValid(false)
        }
      } else {
        setIsTokenValid(false)
      }
    }

    const intervalId = setInterval(() => {
      checkTokenValidity()
    }, 60000 * 10) // Check every 10 minutes
    return () => clearInterval(intervalId)
  }, [])

  // useEffect(() => {
  //   // Post Hog
  //   if (stepUid) {
  //     postHogStepInit()
  //   }
  // }, [stepUid])

  const currentSection = step?.data?.section
  const { sections } = steps ? getPreviousStepsAndSections(steps) : { sections: null }

  // Platform selection should not include sections nor "PageContainer" component
  if (step && ["platform-selection", "mt4-password"].includes(step.uid)) {
    return children
  }

  return (
    <>
      {asPath.includes("welcome") ? (
        children
      ) : (
        <>
          {isTokenValid ? null : (
            <Notification
              title={validationMessages.session_expired}
              onClick={() => {
                goToPage("/signup/welcome")
              }}
            />
          )}
          <PageContainer className="signup-step" gap="40px">
            {sections && (
              <ProgressBar
                sections={sections}
                currentSection={currentSection}
                sectionTranslation={translation.sectionsTranslation}
              />
            )}
            {children}
          </PageContainer>

          <Script
            id="beacon_v2_id"
            dangerouslySetInnerHTML={{
              __html:
                // eslint-disable-next-line quotes, @typescript-eslint/quotes
                '!function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});'
            }}
            async
          />
        </>
      )}
      <Prompt />
    </>
  )
}

export const getStaticProps = async ({
  params,
  previewData
}: {
  params: { uid: string }
  previewData: { GetStaticPropsContext; ref: string }
}) => {
  try {
    const isPreview = !!previewData?.ref
    const client = createClient({ previewData })
    const currentStep = await client.getByUID("signupstep", params.uid)
    const steps = await client.getAllByType("signupstep")
    const { validation } = await getValidationRulesAndMessages(client)

    const clientTN2 = createTN2Client()
    const riskWarnings = await getRiskWarnings(clientTN2)
    const translation = await getTranslations(client, params.uid, "en-gb")

    const category = "signup"
    const subCategory = params.uid === "welcome" ? "welcome" : "step"

    return {
      props: {
        step: currentStep,
        steps: steps,
        isPreview,
        validation,
        translation,
        riskWarnings,
        category,
        subCategory
      },
      revalidate: 60
    }
  } catch (error) {
    console.error(error)
    Sentry.captureException(error)
    return {
      notFound: true,
      revalidate: 60
    }
  }
}

export const getStaticPaths = async () => {
  const client = createClient()
  const steps = await client.getAllByType("signupstep")

  return {
    paths: steps.map((step) => prismicH.asLink(step)),
    fallback: true
  }
}

export default PageStep
